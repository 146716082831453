<template>
  <b-modal
    @show="showModal"
    @hidden="hideModal"
    ref="my-modal"
    centered
    class="rounded-0"
    hide-footer
    :title="updateData.ad_soyad"
  >
    <template #default>
      <div>
        <p>{{ updateData.mesaj }}</p>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ref, watch, defineComponent, toRefs } from '@vue/composition-api';

export default defineComponent({
  props: {
    openModal: {
      type: Boolean,
    },
    updateData: {
      type: Object,
    },
  },
  setup(props, context) {
    const expo = {};
    const { openModal, updateData } = toRefs(props);
    expo.showModal = () => {
      context.refs['my-modal'].show();
    };
    expo.hideModal = () => {
      context.refs['my-modal'].hide();
      context.emit('closeModal', true);
    };

    watch(
      openModal,
      (val) => {
        if (val == true) {
          expo.showModal();
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
